import * as React from 'react';
import './SiteHeader.scss';
import { useLocation } from '@reach/router';
import CityLanguageSelector2 from './CityLanguageSelector2';
import Navbar from './Navbar';
import navigation from '../constants/navigation2.js';
// import languages from '../constants/languages';
import logo from '../images/lookup-evergreen-logo.svg';

function SiteHeaderCity2() {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];
  const isHomepage = location?.pathname === '/en/fountain-hills/';

  return (
    <>
      {isHomepage && (
        <div class="alert-banner">
          <div class="row">
            <a target="_blank" href="/resources/arrival_guide_az.pdf">
              Download the Arrival Guide
            </a>
          </div>
        </div>
      )}
      {location.pathname.split('/')[1] === 'en' ? (
        <>
          <div className="navigation">
            <header className="main-header">
              <div>
                <a href="/en/fountain-hills" className="logo" id="desktop-logo">
                  <img src={logo} alt="Look Up Celebration with Will Graham" />
                </a>
                <div class="sec-center" id="desktop-dropdown">
                  <input
                    class="dropdown"
                    type="checkbox"
                    id="dropdown"
                    name="dropdown"
                  />
                  <label class="for-dropdown" htmlFor="dropdown">
                    Fountain Hills, AZ <i class="uil uil-arrow-down"></i>
                  </label>
                  <div class="section-dropdown">
                    <a href="/en/">
                      General Information<i class="uil uil-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {currentPathLanguage &&
                navigation.primary[currentPathLanguage] && (
                  <CityLanguageSelector2 />
                )}
            </header>
            <div>
              {currentPathLanguage &&
                navigation.primary[currentPathLanguage] && (
                  <Navbar
                    items={navigation.primary[currentPathLanguage]}
                  ></Navbar>
                )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="navigation" id="noco">
            <header className="main-header">
              <div>
                <a href="/es/fountain-hills" className="logo" id="desktop-logo">
                  <img src={logo} alt="Look Up Celebration with Will Graham" />
                </a>
                <div class="sec-center" id="desktop-dropdown">
                  <input
                    class="dropdown"
                    type="checkbox"
                    id="dropdown"
                    name="dropdown"
                  />
                  <label class="for-dropdown" htmlFor="dropdown">
                    Fountain Hills, AZ <i class="uil uil-arrow-down"></i>
                  </label>
                  <div class="section-dropdown">
                    <a href="/es/">
                      Información general <i class="uil uil-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {currentPathLanguage &&
                navigation.primary[currentPathLanguage] && (
                  <CityLanguageSelector2 />
                )}
            </header>
            <div>
              {currentPathLanguage &&
                navigation.primary[currentPathLanguage] && (
                  <Navbar
                    items={navigation.primary[currentPathLanguage]}
                  ></Navbar>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SiteHeaderCity2;
