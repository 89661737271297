export default {
  primary: {
    en: [
      {
        label: 'Main Event',
        href: '/en/fountain-hills/',
        title: 'Main Event',
      },
      {
        label: 'KidzFest',
        href: '/en/fountain-hills/kidzfest',
        title: 'KidzFest',
      },
      {
        label: 'Pre-events',
        href: '#',
        title: 'Pre-events',
        items: [
          // {
          //   label: 'Lift Up',
          //   href: '/en/fountain-hills/lift-up/',
          //   title: 'Lift Up',
          // },
          {
            label: 'Christian Life & Witness Course',
            href: '/en/fountain-hills/course/',
            title: 'Christian Life & Witness Course',
          },
          {
            label: 'Pursuit',
            href: '/en/fountain-hills/pursuit/',
            title: 'Pursuit',
          },
          {
            label: 'Bring A Friend',
            href: '/en/fountain-hills/bring-a-friend/',
            title: 'Bring A Friend',
          },
          {
            label: 'All Pre-events',
            href: '/en/fountain-hills/events/',
            title: 'Pre-events',
          },
        ],
      },
      // {
      //   label: 'About',
      //   href: '/en/about',
      //   title: 'About',
      // },
      // {
      //   label: "Get Involved",
      //   href: "/en/fountain-hills/get-involved",
      //   title: "Get Involved",
      // },
      {
        label: 'Resources',
        href: '/en/fountain-hills/resources',
        title: 'Resources',
      },
      {
        label: 'Give',
        href: 'https://giving.billygraham.org/fountain-hills-look-up-celebration/',
        title: 'Give',
      },
    ],
    es: [
      {
        label: 'Evento Principal',
        href: '/es/fountain-hills/',
        title: 'Evento Principal',
      },
      {
        label: 'KidzFest',
        href: '/es/fountain-hills/kidzfest',
        title: 'KidzFest',
      },
      {
        label: 'PRÓXIMOS EVENTOS',
        href: '/es/fountain-hills/eventos/',
        title: 'PRÓXIMOS EVENTOS',
        items: [
        //   {
        //     label: 'Todos Pré-Eventos',
        //     href: '/es/fountain-hills/eventos/',
        //     title: 'Todos Pré-Eventos',
        //   },
          // {
          //   label: 'Curso de Vida e Testemunho Cristão',
          //   href: '/es/fountain-hills/curso/',
          //   title: 'Curso de Vida e Testemunho Cristão',
          // },
        ],
      },
      {
        label: 'CURSO DE VIDA Y TESTIMONIO CRISTIANO',
        href: '/es/fountain-hills/course/',
        title: 'CURSO DE VIDA Y TESTIMONIO CRISTIANO',
      },
      {
        label: 'Recursos',
        href: '/es/fountain-hills/resources/',
        title: 'Recursos',
      },
      // {
      //   label: 'Voluntários',
      //   href: '/es/fountain-hills/voluntarios',
      //   title: 'Voluntários',
      // },
      // {
      //   label: 'Sobre',
      //   href: '/es/fountain-hills/sobre',
      //   title: 'Sobre',
      // },
      // {
      //   label: 'Recursos',
      //   href: '/pt/recursos',
      //   title: 'Recursos',
      // },
    ],
  },
  footer: {
    en: [
      {
        label: 'Privacy Policy',
        href: '/en/privacy',
        title: 'Privacy Policy',
      },
      {
        label: 'Contact Us',
        href: 'https://billygraham.org/about/contact/',
        title: 'Contact Us',
      },
    ],
    es: [
      {
        label: 'Política de Privacidade',
        href: '/es/politica-de-privacidade/',
        title: 'Política de Privacidade',
      },
      {
        label: 'Contacte-nos',
        href: '/es/contacte-nos',
        title: 'Contacte-nos',
      },
    ],
  },
};
